import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'
import { mediaMax } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ${mediaMax.xs} {
    width: fit-content;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: initial !important;
  overflow-x: clip !important;

  .swiper-slide {
    width: 83%;
  }
`

export const SlideContent = styled.div`
  height: 100%;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ActionWrapper = styled.div`
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  padding-top: 3.5rem;

  ${mediaMax.xs} {
    width: 50%;
    display: none;
  }
`
