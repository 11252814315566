import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

import { Content as WysiwygContent } from '@/components/Wysiwyg/style'
import { ActionWrapper as SliderActionWrapper } from '@/components/Block/Sliders/ImageSlider/style'
import { Wrapper as ArrowWrapper } from '@/components/Block/Sliders/atoms/Arrows/style'

import {
  DropDownLabel,
  Wrapper as DropDownWrapper,
  IconWrapper as DropDownIconWrapper
} from '@/components/Forms/Inputs/Select/style'

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10%;
  position: relative;

  ${SliderActionWrapper} {
    padding-top: 3rem;
  }

  ${mediaMax.xs}{
    width: 100%;
  }

  .circularLabelButton {
    position: absolute;
    bottom: -5%;
    right: 10%;
    z-index: 2;

    text {
      fill: #3f787b;
    }
  }
`

export const RightWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 15px;

  ${WysiwygContent} {
    width: 50%;
    ${mediaMax.xs}{
      width: 100%;
    }
  }

  ${mediaMax.xs}{
    width: 100%;
    padding-left: ${p => p.theme.paddingX.m};
    padding-right: ${p => p.theme.paddingX.m};
  }

  &.unique {
    ${DropDownLabel} {
      text-decoration: none;
      &:after {
        display: none !important;
      }
    }
    ${DropDownWrapper} {
      pointer-events: none;
    }
    ${DropDownIconWrapper} {
      display: none !important;
    }
  }
`
export const Container = styled.div`
  margin: 5rem 0 12rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMax.xs}{
    gap: 6rem;
    flex-direction: column-reverse;
    margin-bottom: 4rem;
    margin-top: 5rem;
  }

  &.regular {
    ${ArrowWrapper} {
      justify-content: flex-start;
    }
  }

  &.inverted {
    flex-direction: row-reverse;

    ${mediaMax.xs}{
      flex-direction: column-reverse;
    }

    ${LeftWrapper} {
      padding-right: 0;
      padding-left: 10%;

      ${mediaMax.xs}{
        padding-left: ${({ theme }) => theme.paddingX.m};
      }
    }

    ${RightWrapper} {
      padding-right: 15px;
      padding-left: 10%;

      ${mediaMax.xs}{
        padding-right: ${p => p.theme.paddingX.m};
        padding-left:  ${p => p.theme.paddingX.m};
      }
    }
  }
`
