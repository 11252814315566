import * as React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { SwiperSlide } from 'swiper/react'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'

import {
  Container,
  Wrapper,
  SwiperContent,
  SlideContent,
  Image,
  ActionWrapper
} from './style'

const ImageSlider = ({ images = [], display = 'regular' }) => {
  const hasOverflow = images.length > 1

  return (
    <Container>
      <Wrapper>
        <SwiperContent
          spaceBetween={ 40 }
          slidesPerView='auto'
          allowTouchMove={ hasOverflow }
          dir={ display === 'inverted' ? 'ltr' : 'rtl' }
        >
          {
            images.map((image, i) => {
              return (
                <SwiperSlide key={ `imageSlide-${i}` }>
                  <SlideContent>
                    <Image loading='lazy' src={ resolveAssetURL(image?.url || image?.src || image) } alt={ image?.alt || `Slide ${i}` } />
                  </SlideContent>
                </SwiperSlide>
              )
            })
          }

          { hasOverflow &&
            <ActionWrapper>
              <Arrows display={ display } />
            </ActionWrapper>}
        </SwiperContent>
      </Wrapper>
    </Container>
  )
}

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  display: PropTypes.oneOf(['regular', 'inverted'])
}

export default ImageSlider
